<template>
  <div class="surface-card p-4 shadow-2 border-round">
    <div class="font-medium text-3xl text-900 mb-3">Produtos Utilizados</div>
    <div class="text-500 mb-5">Informe a quantidade dos produtos utilizados para a retirada do estoque.</div>

    <div class="surface-card grid grid-nogutter border-round shadow-2">
      <div class="col-12 flex p-2 surface-100 border-round-top">
        <div class="p-2 flex-auto text-center md:text-left">
          <span class="text-600 block">Título</span>
          <span class="text-900 font-medium block mt-2">{{ scheduler.title }}</span>
        </div>
        <div class="p-2 flex-auto text-center md:text-left">
          <span class="text-600 block">Paciente</span>
          <span class="text-900 font-medium block mt-2">{{ scheduler.contact.name }}</span>
        </div>
        <Divider align="center" layout="vertical" class="h-full  mx-0 lg:mx-3 surface-border"></Divider>
        <div class="p-2 flex-auto text-center md:text-left">
          <span class="text-600 block">Data Atendimento</span>
          <span class="text-900 font-medium block mt-2">{{ scheduler.startDate }}</span>
        </div>
      </div>
    </div>
    <ul class="list-none p-0 m-0 border-top-1 surface-border mt-3">
      <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
        <div class="text-500 w-full md:w-2 font-medium">Produtos Utilizados</div>
        <div class="text-900 w-full md:w-10">
          <div class="grid mt-0 mr-0">
            <div class="col-12 md:col-6" v-for="product of products" :key="product.id">
              <div class="p-3 border-1 surface-border border-round surface-card">
                <div class="text-900 mb-2">
                  <i class="pi pi-minus mr-2"></i>
                  <span class="font-medium">{{product.name}})</span>
                </div>
                <div>
                  <div class="text-900 w-full md:w-10">
                    <InputNumber inputId="percent"
                                 showButtons
                                 v-model="product.amount" :suffix="product.metricsType"
                                 :min="0" :max="100"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import _ from "lodash";

export default {
  name: 'estoque-tab',
  props: {
    scheduleObject: {
      type: Object
    }
  },
  data() {
    return {
      scheduler: this.scheduleObject,
      products: []
    }
  },
  async mounted() {
    await this.verificarProdutosUsados();
  },
  methods: {

    async verificarProdutosUsados() {
      let produtos = [];
      for(const procedure of this.scheduler.procedures) {
        for(const product of procedure.products) {
          produtos.push(product);
        }
      }
      this.products = _.uniqBy(produtos, 'id');
    },
  }
}
</script>
<style scoped lang="scss">

.p-tabview p {
  line-height: 1.5;
  margin: 0;
}
</style>
