import api from "../configs/api";


export default class ScheduleService {

    async findAll(id) {
        return api.get("/admin/scheduler/user/" + id);
    }

    async findAllClinical() {
        return api.get("/admin/scheduler/clinical");
    }

    async findById(id) {
        return api.get("/admin/scheduler/" + id);
    }

    async save(schedule) {
        return api.post("/admin/scheduler", schedule);
    }

    async delete(id) {
        return api.delete("/admin/scheduler/" + id);
    }
}
