import api from "../configs/api";


export default class ScheduleProcedureService {

    async findBySchedulerId(id) {
        return api.get("/admin/scheduler-procedure/" + id);
    }

    async save(schedulerProcedure, id) {
        return api.post("/admin/scheduler-procedure/" + id, schedulerProcedure);
    }
}
