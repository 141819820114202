<template>
  <TabPanel>
    <template #header>
      <i class="pi pi-calendar"></i>
      <span>Agendamento</span>
    </template>
    <div class="grid">
      <div class="col-12">
        <div class="surface-card p-4 shadow-2 border-round">
          <div class="font-medium text-3xl text-900 mb-3">Informações do Agendamento</div>
          <div class="text-500 mb-5">Informações gerais do agendamento do paciente.</div>

          <div class="col-12 flex p-2 surface-100 shadow-2 border-round-top">
            <div class="p-2 flex-auto text-center md:text-left">
              <span class="text-600 block">Título</span>
              <span class="text-900 font-medium block mt-2">{{ schedule.title }}</span>
            </div>
            <Divider align="center" layout="vertical" class="h-full  mx-0 lg:mx-3 surface-border"></Divider>
            <div class="p-2 flex-auto text-center md:text-left">
              <span class="text-600 block">Paciente</span>
              <span class="text-900 font-medium block mt-2">{{ schedule.contact.name }}</span>
            </div>
            <Divider align="center" layout="vertical" class="h-full  mx-0 lg:mx-3 surface-border"></Divider>
            <div class="p-2 flex-auto text-center md:text-left">
              <span class="text-600 block">Data Atendimento</span>
              <span class="text-900 font-medium block mt-2">{{ schedule.startDate }}</span>
            </div>
          </div>
          <Panel header="Detalhes" class="mt-3">
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-12">
                <label for="procedimentos" :class="{'p-error': v$.schedule.procedures.$error}">Procedimentos *</label>
                <MultiSelect id="procedimentos" v-model="schedule.procedures"
                             :options="procedures"
                             option-label="name"
                             data-key="id"
                             @change="saveProcedure($event)"
                             :loading="isLoadProcedures"
                             @blur="v$.schedule.procedures.$touch"
                             placeholder="Selecione os Procedimentos"
                             display="chip"
                             :class="{'p-invalid': v$.schedule.procedures.$error}"/>
                <small class="p-error" v-if="v$.schedule.procedures.$error">Procedimento(s) é obrigatório.</small>
              </div>
              <div class="field col-4 md:col-3">
                <label for="data" :class="{'p-error': v$.schedule.startDate.$error}">Início *</label>
                <span class="p-input-icon-right">
                  <i class="pi pi-calendar" />
                  <InputMask id="data" v-model="schedule.startDate"
                             show-icon
                             mask="99/99/9999 99:99"
                             :class="{'p-invalid': v$.schedule.startDate.$error}"
                             @blur="v$.schedule.startDate.$touch"
                  />
                  </span>
                <small class="p-error" v-if="v$.schedule.startDate.$error">Data Inícial é obrigatório.</small>
              </div>
              <div class="field col-4 md:col-3">
                <label for="fim">Fim</label>
                <span class="p-input-icon-right">
                      <i class="pi pi-calendar" />
                      <InputMask id="fim"
                                 v-model="schedule.endDate"
                                 show-icon
                                 mask="99/99/9999 99:99"
                                 :class="{'p-invalid': v$.schedule.endDate.$error}"
                                 @blur="v$.schedule.endDate.$touch"
                      />
                </span>
              </div>
              <div class="field col-12 md:col-6">
                <label for="status" :class="{'p-error': v$.schedule.schedulerType.$error}">Status do Atendimento
                  *</label>
                <Dropdown id="status" :options="status"
                          v-model="schedule.schedulerType"
                          option-label="name" option-value="code"
                          placeholder="Selecione o status"
                          :class="{'p-invalid': v$.schedule.schedulerType.$error}"
                          @blur="v$.schedule.schedulerType.$touch"
                />
                <small class="p-error" v-if="v$.schedule.schedulerType.$error">Status Atendimento é obrigatório.</small>
              </div>
              <div class="field col-12 md:col-12">
                <label for="name" :class="{'p-error': v$.schedule.user.$error}">Profissional *</label>
                <Dropdown id="name" v-model="schedule.user"
                          :options="users"
                          @blur="v$.schedule.user.$touch"
                          option-label="name"
                          required="true"
                          :class="{'p-invalid': v$.schedule.user.$error}"
                          placeholder="Selecione um Profissional"
                          :filter="true"
                          :disabled="isEspecialista"
                          filterPlaceholder="Pesquisar Profissional"/>
                <small class="p-error" v-if="v$.schedule.user.$error">Profissional é obrigatório.</small>
              </div>
            </div>
          </Panel>
        </div>
      </div>
    </div>
  </TabPanel>
</template>
<script>
import ProcedureService from "@/service/ProcedureService";
import {useVuelidate} from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import UserService from "@/service/UserService";
import moment from "moment/moment";

// const dateBefore = (param) => (dateEnd) => !helpers.req(dateEnd) || dateEnd.isBefore(param);

export default {
  name: 'agendamento-tab',
  setup: () => ({ v$: useVuelidate() }),
  created() {
    moment.locale('pt-BR');
    this.procedureService = new ProcedureService();
    this.userService = new UserService();
  },
  props: {
    isEspecialista: {
      type: Boolean
    },
    scheduleObject: {
      type: Object
    }
  },
  computed: {
    isLoadProcedures() {
      return this.procedures.length === 0;
    }
  },
  data() {
    return {
      schedule: this.scheduleObject,
      procedureService: null,
      userService: null,
      procedures: [],
      users: [],
      status: [
        {name: 'CONSULTA', code: 'CONSULTA'},
        {name: 'CONCLUÍDO', code: 'CONCLUIDO'},
        {name: 'CANCELADO', code: 'CANCELADO'},
        {name: 'RETORNO', code: 'RETORNO'}
      ],
    }
  },

  validations() {
    return {
      schedule: {
        title: {
          required: helpers.withMessage('Campo Título é obrigatório.', required)
        },
        contact: {
          required: helpers.withMessage("Campo Contato é obrigatório.", required)
        },
        procedures: {
          required: helpers.withMessage("Campo Procedimento é obrigatório.", required)
        },
        startDate: {
          required: helpers.withMessage("Campo Data Inícial é obrigatório.", required)
        },
        endDate: {
          required: helpers.withMessage("Campo Data Final é obrigatório.", required)
        },
        schedulerType: {
          required: helpers.withMessage("Campo Status do Atendimento é obrigatório.", required)
        },
        user: {
          required: helpers.withMessage("Campo Profissional é obrigatório.", required)
        }
      }
    }
  },

  async mounted() {
    await Promise.all([
      this.findAllUser(),
      this.findAllProcedures()
    ]);
    this.schedule.startDate = moment(this.schedule.startDate, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY HH:mm");
    if(this.schedule.endDate) {
      this.schedule.endDate = moment(this.schedule.endDate, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY HH:mm");
    }
  },
  methods: {

    async findAllProcedures() {
      const response = await this.procedureService.findAll();
      if(response.status === 200) {
        this.procedures = response.data.content;
      }
    },
    async findAllUser() {
      const response = await this.userService.findAll();
      if(response.status === 200) {
        this.users = response.data.content;
      }
    },
    saveProcedure() {
      this.$emit("saveProcedure");
    }
  }
}
</script>
<style scoped lang="scss">

.p-tabview p {
  line-height: 1.5;
  margin: 0;
}
</style>
