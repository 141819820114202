<template>
  <div class="surface-card p-4 shadow-2 border-round">

    <TabView class="tabview-custom">
      <TabPanel v-if="isLoadPrimaryTabs">
        <template #header>
          <i class="pi pi-search"></i>
          <span>Agendamento</span>
        </template>
        <agendamento-tab @saveProcedure="save($event)" v-bind:is-especialista="isEspecialista"
                         v-bind:schedule-object="schedule"/>
      </TabPanel>
      <TabPanel v-if="isLoadPrimaryTabs">
        <template #header>
          <i class="pi pi-search"></i>
          <span>Anamnese</span>
        </template>
        <anamnese-tab :schedule-object="schedule"/>
      </TabPanel>

      <TabPanel v-if="isLoadStockTabs" :disabled="schedule.schedulerType !== 'CONCLUIDO'">
        <template #header>
          <i class="pi pi-shopping-bag"></i>
          <span>Produtos Utilizados</span>
        </template>
        <estoque-tab ref="estoqueTab" :schedule-object="schedule"/>
      </TabPanel>

      <TabPanel v-if="isLoadFinanceTabs" :disabled="schedule.schedulerType !== 'CONCLUIDO'">
        <template #header>
          <i class="pi pi-dollar"></i>
          <span>Financeiro</span>
        </template>
        <financeiro-tab
            ref="financeiroTab"
            :schedule-object="schedule"/>
      </TabPanel>
    </TabView>
    <Toolbar class="mt-2">
      <template v-slot:end>
        <div class="my-2">
          <Button label="Cancelar" icon="pi" class="p-button-outlined mr-2" @click="$router.back()"/>
          <Button label="Salvar" icon="pi pi-save" class="p-button-success" @click="save"/>
        </div>
      </template>
    </Toolbar>
  </div>
</template>

<script>
import moment from "moment";
import ScheduleService from "@/service/ScheduleService";
import {useVuelidate} from "@vuelidate/core";
import _ from "lodash"
import ScheduleProcedureService from "@/service/ScheduleProcedureService";
import AgendamentoTab from "@/components/clinical/agendamento/status/AgendamentoTab";
import AnamneseTab from "@/components/clinical/agendamento/status/AnamneseTab";
import FinanceiroTab from "@/components/clinical/agendamento/status/FinanceiroTab";
import EstoqueTab from "@/components/clinical/agendamento/status/EstoqueTab";
import ProductService from "@/service/ProductService";

export default {
  name: "agendar",
  components: {EstoqueTab, FinanceiroTab, AnamneseTab, AgendamentoTab},
  setup: () => ({ v$: useVuelidate() }),
  created() {
    moment.locale('pt-BR');
    this.scheduleService = new ScheduleService();
    this.schedulerProcedureService = new ScheduleProcedureService();
    this.productService = new ProductService();

  },
  async mounted() {
    await this.loadPage();
  },
  computed: {
    isLoadPrimaryTabs() {
      return this.schedule !== null;
    },
    isLoadStockTabs() {
      return this.schedule !== null;
    },
    isLoadFinanceTabs() {
      return this.schedule !== null;
    }
  },
  data() {
    return {
      schedule: null,
      scheduleService: null,
      schedulerProcedureService: null,
      productService: null,
      contactService: null,
      userService: null,
      contacts: null,
      users: [],
      idSchedule: null,
      loader: null,
      isEspecialista: false
    }
  },

  methods: {

    async loadPage() {
      this.loader = this.$loading.show();
      this.idSchedule = this.$route.query.id;

      this.verifyRole();

      if(this.idSchedule) {
        await this.findById();
      }
      this.loader.hide();
    },

    async findById() {
      const response = await this.scheduleService.findById(this.idSchedule);
      if (response.status === 200) {
        this.schedule = response.data;
      }
    },

    verifyRole() {
      const roles = JSON.parse(localStorage.getItem("user")).roles;

      const rolesString = [];
      roles.forEach((role) => {
        rolesString.push(role.name);
      });

      if(_.includes(rolesString, 'ROLE_ESPECIALISTA') && !_.includes(rolesString, 'ROLE_ADMIN')) {
        this.isEspecialista = true;
      }
    },

    async saveProducts() {
      const products = this.$refs.estoqueTab.products;
      await this.productService.saveAll(products);
    },

    async save() {
      const isFormValid = await this.v$.$validate();
      if(!isFormValid) {
        return;
      }

      this.loader = this.$loading.show();

      try {
        this.schedule.startDate = moment(this.schedule.startDate, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY HH:mm");
        if(this.schedule.endDate) {
          this.schedule.endDate = moment(this.schedule.endDate, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY HH:mm");
        }

        if(this.$refs.financeiroTab.schedule.schedulerType === 'CONCLUIDO') {
          this.schedule.total = this.$refs.financeiroTab.totalProcedimentos;
        } else {
          this.schedule.total = 0;
        }

        const response = await this.scheduleService.save(this.schedule);

        if(response.status === 200) {
          this.saveProducts();

          await this.saveSchedulerProcedure();
          this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Agendamento cadastrado com sucesso.', life: 3000});
        }
        await this.$refs.financeiroTab.findBySchedulerProcedureByScheduler();
        await this.$refs.financeiroTab.calcTotalProcedimentos();
        this.loader.hide();
      } catch(e) {
        this.loader.hide();
        this.$toast.add({
          severity: 'error',
          summary: 'ERRO',
          detail: 'Ocorreu um erro interno no servidor, tente novamente mais tarde.',
          life: 3000
        });
      }
    },

    async saveSchedulerProcedure() {
      const schedulerProcedures = this.$refs.financeiroTab.schedulerProcedures;
      const responseSchedulerProcedure = await this.schedulerProcedureService.save(schedulerProcedures, this.schedule.id);
      if(responseSchedulerProcedure.status === 200) {
        return;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.tabview-custom {
  i, span {
    vertical-align: middle;
  }

  span {
    margin: 0 .5rem;
  }
}

.p-tabview p {
  line-height: 1.5;
  margin: 0;
}
</style>
