<template>
  <div class="surface-card p-4 shadow-2 border-round">
    <div class="font-medium text-3xl text-900 mb-3">Anamnese</div>
    <div class="text-500 mb-5">Informações gerais do tratamento realizado no paciente.</div>
    <div class="surface-card grid grid-nogutter border-round shadow-2">
      <div class="col-12 flex p-2 surface-100 border-round-top">
        <div class="p-2 flex-auto text-center md:text-left">
          <span class="text-600 block">Título</span>
          <span class="text-900 font-medium block mt-2">{{ schedule.title }}</span>
        </div>
        <div class="p-2 flex-auto text-center md:text-left">
          <span class="text-600 block">Paciente</span>
          <span class="text-900 font-medium block mt-2">{{ schedule.contact.name }}</span>
        </div>
        <Divider align="center" layout="vertical" class="h-full  mx-0 lg:mx-3 surface-border"></Divider>
        <div class="p-2 flex-auto text-center md:text-left">
          <span class="text-600 block">Data Atendimento</span>
          <span class="text-900 font-medium block mt-2">{{ schedule.startDate }}</span>
        </div>
      </div>
    </div>
    <Accordion :activeIndex="0" class="mt-3">
      <AccordionTab header="Avaliação">
        <Editor v-model="schedule.avail" editorStyle="height: 320px"/>
      </AccordionTab>
      <AccordionTab header="Pós-Procedimento">
        <Editor v-model="schedule.pos" editorStyle="height: 320px" class="col-12"/>
      </AccordionTab>
      <AccordionTab header="Efeitos Diversos">
        <Editor v-model="schedule.problem" editorStyle="height: 320px" class="col-12"/>
      </AccordionTab>
    </Accordion>
  </div>
</template>
<script>
export default {
  name: 'anamnese-tab',
  props: {
    scheduleObject: Object
  },
  data() {
    return {
      schedule: this.scheduleObject
    }
  }
}
</script>
<style scoped lang="scss">

.p-tabview p {
  line-height: 1.5;
  margin: 0;
}
</style>
