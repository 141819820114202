import api from "../configs/api";

export default class UserService {

    async findById(id) {
		return api.get("/admin/user/" + id);
	}

	async save(user) {
		return api.post(`/admin/user`, user);
    }

	async findAll(size, page, name) {
		if(name) {
			return api.get(`/admin/user?size=${size}&page=${page}&name=${name}`);
		}
		return api.get(`/admin/user?size=${size}&page=${page}`);
	}

	async findByClinicalProfessional() {
		return api.get(`/admin/user/professional`);
	}

	async findByClinicalAndId(id) {
		return api.get(`/admin/user/${id}`);
	}

	async delete(id) {
		return api.delete(`/admin/user/${id}`);
	}

	async updatePassword(user) {
		return api.post("/admin/user/account/change-password", user);
	}
}
